import React from 'react'
import styled from 'styled-components'

import {Link} from 'gatsby'
import { getImage } from "gatsby-plugin-image"

import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'


const Wrapper = styled.div`
    .banner-image,
    .banner-image::before,
    .banner-image::after{
        background-position: center center;
        height: 400px;
    }
    height: 400px;
    &:hover{
        cursor: pointer;
    }
`;

const Gradient = styled.div`
    width: 100%;
    height: 100%;
    padding: 40px 40px;
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,0.4724090319721639) 44%, rgba(255,255,255,0) 100%);
    
    h2{
        font-family: D-DINExpBold;
        color: white;
    }
    p{
        color: white;
        opacity: 0.7;
    }
    h6{
        font-family: D-DINExpBold;
        color: var(--pink);
    }
`;

export default function SingleBlogTile({bannerImage, title, date, previewText, slug}){

    const image = getImage(bannerImage)
    const bgImage = convertToBgImage(image)

    return(
        <Link to={`/blogs/${slug.current}`}>
        <Wrapper>
                <BackgroundImage
                    Tag="div"
                    {...bgImage}
                    preserveStackingContext
                    className="banner-image"
                    style={{
                        width: '100%',
                        height: '',
                        backgroundPosition: ''
                    }}
                >
                    <Gradient>
                        <h2>{title}</h2>
                        <p>{previewText}</p>
                        <h6>{date}</h6>
                    </Gradient>
                </BackgroundImage>
        </Wrapper>
        </Link>
    )
}