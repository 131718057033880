import React from 'react'
import styled from 'styled-components'
import {graphql} from 'gatsby';
import SingleBlogTile from '../components/SingleBlogTile';
import SEO from '../components/SEO';

const Wrapper = styled.div`
    margin-top: 25vh;
    h1{
        color: black;
        text-align: center;
        padding-bottom: 60px;
        position: relative;
        z-index: 3;
    }
    .p-0{
        padding: 0px;
    }
    .brush{
        position: relative;
        z-index: 2;
        margin-top: -100px;
        margin-bottom: -20px;
    }
`

export default function Blog({data}){
    return(
        <>
        <SEO 
            title="Our Blog"
            description="Read up on our informative articles cover everything cananbis! From strain recommendations to guides for beginners we are ready to educate you!"
        />
        <Wrapper>
            <h1>Latest Blog Articles</h1>
            <img src="/svg/BrushStroke.svg" className="brush"/>
            <div className="blog-tiles">
                <div div className="container-fluid">
                    <div className="row">
                        {data.blogs.nodes.map((post) =>(
                            <div className="col-lg-6 col-12 p-0">

                                <SingleBlogTile 
                                    bannerImage={post.imagePlusAltHeader.image.asset.gatsbyImageData}
                                    title={post.title}
                                    previewText={post.previewText}
                                    date={post._createdAt}
                                    slug={post.slug}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </Wrapper>
        </>
    )
}

export const query = graphql`
    query{
        blogs: allSanityFancyblog {
            nodes {
                id
                title
                previewText
                slug{
                    current
                }
                _createdAt(formatString: "MMMM DD, YYYY")
                imagePlusAltHeader {
                    alt
                    image{
                        asset{
                            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, height: 400)
                        }
                        
                    }
                }
            }
        }
    }
`;